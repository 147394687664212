import { newBaseImageUrl } from "./api";
import convertImageUrl from "../functions/convertImageUrl";
import CheckboxEmpty from "./CheckboxEmpty";
import CheckboxChecked from "./CheckboxChecked";

function SponsorContainer(props: {
  desc: string;
  name: string;
  image: string;
  hovedSponsor: boolean;
  episodeSponsor: boolean;
  ambassador: boolean;
}) {
  return (
    <div className="sponsor-container">
      <div className="sponsor-container__image-container">
        <img
          src={convertImageUrl(newBaseImageUrl + props.image)}
          className="sponsor-image"
          alt="Sponsors"
        />
      </div>
      <div className="sponsor-container__text-container">
        <h5>{props.name}</h5>
        <p>{props.desc}</p>
        <div className="sponsor-container__text-container__sponsor-info">
          {props.hovedSponsor === true ? (
            <div>
              {/* <img src={checkbox} alt="checkbox" className="checkbox" /> */}
              <CheckboxChecked />
              <h6>Hovedsponsor</h6>
            </div>
          ) : (
            <div>
              <CheckboxEmpty />
              <h6>Hovedsponsor</h6>
            </div>
          )}
          {props.episodeSponsor === true ? (
            <div>
              <CheckboxChecked />
              <h6>Episodesponsor</h6>
            </div>
          ) : (
            <div>
              <CheckboxEmpty />
              <h6>Episodesponsor</h6>
            </div>
          )}
          {props.ambassador === true ? (
            <div>
              <CheckboxChecked />
              <h6>Ambassadør</h6>
            </div>
          ) : (
            <div>
              <CheckboxEmpty />
              <h6>Ambassadør</h6>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SponsorContainer;
