import React from "react";

function Ages() {
  return (
    <div className="ages">
      <p id="alder">Alder:</p>
      <p>13-17</p>
      <p>18-24</p>
      <p>25-34</p>
      <p>35-44</p>
      <p>45-54</p>
      <p>55+</p>
    </div>
  );
}

export default Ages;
