import React from "react";

function CheckboxChecked(props: any) {
  const { width = "35px", height = "35px", fill = "#1E103B", ...rest } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 65.91 75.34" {...rest}>
      <g>
        <path
          fill={fill}
          d="M47.54,8.43H17.93c-0.1,0-0.23,0.02-0.33,0.02C7.77,8.87,0,16.91,0,26.81v30.57c0,0.13,0,0.23,0,0.34
              c0.39,9.61,8.2,17.32,17.86,17.6c0,0,0.04,0,0.07,0h0.07c0.13,0,0.26,0.02,0.39,0.02c0.1,0,0.23-0.02,0.36-0.02h28.43
              c0.13,0,0.23,0.02,0.36,0.02c9.89-0.02,17.96-7.82,18.35-17.62c0.03-0.11,0.03-0.21,0.03-0.34V26.81
              C65.91,16.66,57.69,8.43,47.54,8.43z M59.88,56.6c0,0.12,0,0.23,0,0.36c0,3.41-1.37,6.49-3.59,8.74
              c-2.22,2.2-5.26,3.59-8.62,3.6c-0.07,0-0.1,0-0.13,0h-0.26c-0.16,0-0.33,0-0.46,0H18.02c-3.3-0.1-6.24-1.45-8.39-3.6
              c-2.26-2.25-3.63-5.33-3.63-8.74c0-0.5,0.03-0.98,0.09-1.47H6.01V26.81c0-3.41,1.37-6.5,3.63-8.75
              c2.18-2.19,5.19-3.56,8.52-3.61h28.76c0.23,0,0.43,0,0.62,0c3.42,0,6.49,1.38,8.75,3.61c2.22,2.25,3.59,5.34,3.59,8.75V56.6z"
        />
        <path
          fill={fill}
          d="M35.5,1.93v44.59c0,0.9-1.15,1.28-1.69,0.55L18.79,26.68c-0.63-0.86-1.84-1.04-2.7-0.41l-2.42,1.79
              c-0.86,0.63-1.04,1.84-0.41,2.7l22.43,30.46c0.63,0.86,1.84,1.04,2.7,0.41l3.19-2.35c0.49-0.36,0.79-0.94,0.79-1.55V1.93
              C42.37,0.86,41.5,0,40.44,0h-3.01C36.36,0,35.5,0.86,35.5,1.93z"
        />
      </g>
    </svg>
  );
}

export default CheckboxChecked;
