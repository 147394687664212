import React from "react";

function CheckboxEmpty(props: any) {
  const { width = "35px", height = "35px", fill = "#1E103B", ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65.9 75.3"
      {...rest}
      className="checkboxEmpty"
    >
      <g>
        <path
          fill={fill}
          d="M47.5,8.4H17.9c-0.1,0-0.2,0-0.3,0C7.8,8.9,0,16.9,0,26.8v30.6c0,0.1,0,0.2,0,0.3
              C0.4,67.3,8.2,75,17.9,75.3c0,0,0,0,0.1,0H18c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.4,0h28.4c0.1,0,0.2,0,0.4,0
              c9.9,0,18-7.8,18.3-17.6c0-0.1,0-0.2,0-0.3V26.8C65.9,16.7,57.7,8.4,47.5,8.4z M59.9,56.6c0,0.1,0,0.2,0,0.4
              c0,3.4-1.4,6.5-3.6,8.7c-2.2,2.2-5.3,3.6-8.6,3.6c-0.1,0-0.1,0-0.1,0h-0.3c-0.2,0-0.3,0-0.5,0H18c-3.3-0.1-6.2-1.4-8.4-3.6
              C7.4,63.5,6,60.4,6,57c0-0.5,0-1,0.1-1.5H6V26.8c0-3.4,1.4-6.5,3.6-8.8c2.2-2.2,5.2-3.6,8.5-3.6h28.8c0.2,0,0.4,0,0.6,0
              c3.4,0,6.5,1.4,8.8,3.6c2.2,2.2,3.6,5.3,3.6,8.8C59.9,26.8,59.9,56.6,59.9,56.6z"
        />
      </g>
    </svg>
  );
}

export default CheckboxEmpty;
